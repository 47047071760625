<template>
  <div></div>
</template>

<script>
export default {
  name: "GoGoogleMap",
  components: {},
  data() {
    return {
      lineId: this.$route.query.lineid ? this.$route.query.lineid : "",
      type: this.$route.query.type ? this.$route.query.type : "ALL",
      location: this.$route.query.location ? this.$route.query.location : "",
      lat: this.$route.query.lat ? this.$route.query.lat : "",
      long: this.$route.query.long ? this.$route.query.long : "",
      product_list: this.$route.query.product ? this.$route.query.product : "",
      lineProfile: {
        // userId: "Ubae30bf2f781117833ef37831e65c38a",
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      branchList: []
    };
  },
  created() {
    // this.toGoogleMap();
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    getProductList() {
      console.log("getProductList()");
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/location/productList`, requestOptions)
        .then(response => response.json())
        .then(async result => {
          console.log(result);
          if (result.result == 1) {
            var resdata = result.detail;
            this.branchList = resdata;

            this.toGoogleMap();
          }
        })
        .catch(error => {
          console.log("error", error);
          this.toGoogleMap();
        });
    },
    toGoogleMap() {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        lineId:
          this.lineProfile.userId != "" ? this.lineProfile.userId : this.lineId,
        AdOrSKL: this.type,
        location: this.location,
        ProductType:
          this.branchList.find(x => x.code === this.product_list).name ?? ""
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/reportMap`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            window.location =
              "https://www.google.com/maps/search/?api=1&query=" +
              this.lat +
              "," +
              this.long;
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  },
  beforeCreate: async function() {
    await this.$liff
      .init({ liffId: this.$liff_ID_Map })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.getProductList();
              this.checkTerm(profile.userId);
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.getProductList();
            this.checkTerm(profile.userId);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  }
};
</script>

<style scoped></style>
